import { AccountCircle } from '@mui/icons-material';
import { AppBar, Grid } from '@mui/material';
import { signOut, useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Router, { useRouter } from 'next/router';
import { FunctionComponent, memo } from 'react';
import { v4 } from 'uuid';

import miniDevyx from '../public/img/devyx_simbol_mini.png';
import devyx from '../public/img/devyx-colors.webp';
import { getUrlAccount } from '../util/auth';
import useBreakpoints from './Hooks/useBreakpoints';
import Line from './Line';
import { useAuth } from './Providers/Auth';
const SubHeader = dynamic(() => import('./SubHeader'));
const MobileMenu = dynamic(() => import('./MobileMenu'));
{
  /* const LangMenu = dynamic(() => import('./LangMenu'));*/
}
const Elevation = dynamic(() => import('./Header/Elevation'));

export interface IPages {
  path: string;
  label: string;
  description?: string;
}

export const pages: { [key: string]: IPages[] } = {
  'Muito prazer, Devyx!': [
    { path: 'who-we-are', label: 'Quem somos' },
    { path: 'career', label: 'Carreira' },
  ],
  'O que Devyx oferece': [
    {
      path: 'buildr',
      label: 'buildr',
      description: 'Construa landing pages de forma fácil e rápida.',
    },
    {
      path: 'trader',
      label: 'trader',
      description: 'Precisa automatizar suas cobranças? A Devyx resolve!',
    },
    {
      path: 'zoug',
      label: 'zoug',
      description:
        'Não tem plataforma para gerenciar disparo de SMS para seus clientes?',
    },
    {
      path: 'valida',
      label: 'valida',
      description: 'A sua base precisa ser verificada?',
    },
    {
      path: 'business-boost',
      label: 'business boost',
      description: 'Suporte e parceria para você nosso Cliente',
    },
  ],
  'Nossas Soluções': [
    { path: 'automation', label: 'Automação de cobrança' },
    { path: 'leads', label: 'Captura de Leads' },
    { path: 'fidelizacao', label: 'Aceite Digital para Fidelização' },
    { path: 'gerenciamento', label: 'Gerenciamento de disparos de SMS' },
    { path: 'pesquisas', label: 'Pesquisa e Relacionamento' },
  ],
  Setores: [
    { path: 'telecomunicacoes', label: 'Telecomunicações' },
    { path: 'financeiro', label: 'Financeiro' },
    { path: 'varejo', label: 'Varejo' },
    { path: 'publicidade', label: 'Publicidade' },
  ],
  'Fale com a Devyx': [
    { path: 'contato', label: 'Contato' },
    { path: 'faq', label: 'FAQ' },
    /* { path: "devyx-api", label: "Devyx API" },
    { path: "status", label: "Status da Plataforma" },
    { path: "help", label: "Helpdesk" }, */
  ],
};

const Header: FunctionComponent = () => {
  const { user, login } = useAuth();
  const session = useSession();
  const { pathname } = useRouter();
  const { isDesktop, isLaptop } = useBreakpoints();

  return (
    <>
      <Elevation>
        <AppBar position="fixed">
          <Grid
            container
            direction="row"
            justifyContent={{ xs: 'space-around', lg: 'center' }}
            alignItems="center"
            maxWidth={'1440px'}
            gap={{ xs: '27px', lg: '32px' }}
            wrap="nowrap"
          >
            {!isDesktop && <MobileMenu />}
            <Grid
              item
              onClick={() => Router.push('/')}
              width={{ xs: '80px', lg: '109px' }}
              height={{ xs: '31px', lg: '40px' }}
              marginRight={{ xs: '29px', lg: '86px' }}
              style={{ cursor: 'pointer' }}
            >
              <Image
                src={devyx.src}
                alt="Devyx Logo"
                width={isDesktop ? '109px' : '80px'}
                height={isDesktop ? '40px' : '31px'}
              />
            </Grid>
            {isDesktop && (
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={'auto'}
                gap={'24px'}
              >
                {Object.keys(pages).map(
                  page =>
                    page.toLowerCase() != 'nossas soluções' && (
                      <SubHeader key={v4()} page={page} active={pathname} />
                    ),
                )}
              </Grid>
            )}
            {session.status !== 'authenticated' ? (
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                onClick={() =>
                  login({ redirectUri: `${window.location.origin}/planos` })
                }
                gap={{ lg: '5px' }}
                style={{
                  background: 'none',
                  textDecoration: 'none',
                  backgroundImage:
                    'linear-gradient(white, white), radial-gradient(circle at 100%, var(--primary-blue) 0%, var(--primary-green) 100%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                  cursor: 'pointer',
                }}
                border={`double ${isDesktop ? '1px' : '2px'} transparent`}
                borderRadius={'4px'}
                height={{ xs: '32px', lg: '40px' }}
                width={{ xs: '128px', lg: '156px' }}
              >
                <Grid item>
                  <Image
                    src={miniDevyx.src}
                    alt="Devyx Symbol"
                    width={isDesktop ? 19 : 14}
                    height={isDesktop ? 21 : 16}
                  />
                </Grid>
                <Grid
                  item
                  className={`${
                    isDesktop ? 'barlow--size18' : 'barlow--size14'
                  } bold`}
                >
                  Minha Devyx
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                width={{ xs: '138px', lg: '166px' }}
                gap="5px"
                wrap="nowrap"
              >
                <AccountCircle htmlColor="var(--primary-gray-dark)" />
                <Grid item container direction="column">
                  <Grid
                    item
                    className={`${
                      isDesktop ? 'barlow--size18' : 'barlow--size14'
                    } bold`}
                  >
                    {user?.name.split(' ')[0]}
                  </Grid>
                  <Grid item container gap="5px">
                    <Grid
                      item
                      onClick={() => Router.push(getUrlAccount())}
                      className={`${
                        isDesktop ? 'open-sans--size14' : 'open-sans--size12'
                      }`}
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      Minha Conta
                    </Grid>
                    <Grid
                      item
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      className={`${
                        isDesktop ? 'open-sans--size14' : 'open-sans--size12'
                      }`}
                      onClick={() => signOut()}
                    >
                      Sair
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* <LangMenu /> */}
          </Grid>
        </AppBar>
      </Elevation>
      {isLaptop ? (
        <Line
          style={{ margin: '71px 24px 0px', width: 'calc(100vw - 48px)' }}
        />
      ) : (
        <div style={{ margin: '71px' }} />
      )}
    </>
  );
};

export default memo(Header);
